<template>
  <div>
    <b-card title="Update User">
      <validation-observer
        ref="updateUserValidator"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    name="name"
                    placeholder="Enter name"
                  />
                  <span class="text-danger error-msg">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Username"
                label-for="username"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="form.username"
                    name="username"
                    placeholder="Enter username"
                  />
                  <span class="text-danger error-msg">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Password"
            label-for="password"
          >
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="min:6"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                name="password"
                placeholder="Enter password"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Confirm password"
            label-for="password_confirmation"
          >
            <validation-provider
              v-slot="{ errors }"
              name="confirm password"
              rules="confirmed:password|min:6"
            >
              <b-form-input
                id="password_confirmation"
                v-model="form.password_confirmation"
                name="password_confirmation"
                placeholder="Confrim password"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Role"
            label-for="role"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Role"
              rules="required"
            >
              <multiselect
                v-model="form.role"
                :options="roles"
                :multiple="false"
                open-direction="bottom"
                :show-no-results="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :internal-search="true"
                :close-on-select="true"
                :allow-empty="false"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Postcode Groups"
            label-for="postcode_group"
          >
            <validation-provider
              v-slot="{ errors }"
              name="postcode group"
              rules="required"
            >
              <multiselect
                id="postcode_group"
                v-model="assingPostalGroupForm.postal_group_id"
                :options="postcodeGroups"
                :multiple="true"
                label="group_name"
                track-by="id"
                open-direction="bottom"
                :show-no-results="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :internal-search="true"
                :close-on-select="true"
                :allow-empty="false"
              />
              <span class="text-danger error-msg">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              type="submit"
            >
              UPDATE
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const postcodeModule = createNamespacedHelpers('postcode')
const userModule = createNamespacedHelpers('user')

export default {
  data() {
    return {
      form: {
        name: '',
        username: '',
        password: null,
        password_confirmation: null,
        role: '',
      },
      currentUsername: null,
      assingPostalGroupForm: {
        user_id: null,
        postal_group_id: [],
      },
      roles: ['admin', 'csr', 'technician'],
      postcodeGroups: [],
    }
  },
  async mounted() {
    this.UPDATE_LOADER(true)
    await this.fetchUser()
    await this.fetchAllPostcodeGroups()
    this.UPDATE_LOADER(false)
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...postcodeModule.mapActions(['FETCH_ALL_POSTCODE_GROUPS']),
    ...userModule.mapActions(['FETCH_SINGLE_USER', 'UPDATE_USER', 'ASSIGN_POSTAL_GROUP_TO_USER']),

    async fetchUser() {
      try {
        this.UPDATE_LOADER(true)
        const resp = await this.FETCH_SINGLE_USER(this.$route.params.id)
        this.form.name = resp[0].name
        this.form.username = resp[0].username
        this.currentUsername = resp[0].username
        this.form.role = resp[0].role
        this.assingPostalGroupForm.user_id = resp[0].id
        this.assingPostalGroupForm.postal_group_id = resp[0].user_postal_group.map(item => item.postal_code_group)
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },
    async fetchAllPostcodeGroups() {
      try {
        this.UPDATE_LOADER(true)
        this.postcodeGroups = await this.FETCH_ALL_POSTCODE_GROUPS()
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },
    onSubmit() {
      this.$refs.updateUserValidator.validate().then(async success => {
        if (success) {
          const newForm = {
            name: this.form.name,
            username: this.form.username,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
            role: this.form.role,
          }
          if ((this.form.password === null || this.form.password_confirmation === null)
          || (this.form.password === '' || this.form.password_confirmation === '')) {
            delete newForm.password
            delete newForm.password_confirmation
          }
          if (this.currentUsername === this.form.username) {
            delete newForm.username
          }
          const resp = await this.UPDATE_USER({ id: this.$route.params.id, data: newForm })
          this.form.password = null
          this.form.password_confirmation = null
          if (resp) {
            const groupIds = this.assingPostalGroupForm.postal_group_id.map(({ id }) => id)
            const data = {
              user_id: this.assingPostalGroupForm.user_id,
              postal_group_id: groupIds,
            }
            await this.ASSIGN_POSTAL_GROUP_TO_USER(data)
            this.$nextTick(() => {
              this.$refs.updateUserValidator.reset()
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'User Updated!',
                icon: 'PlusIcon',
                variant: 'success',
                text: 'User has been updated successfully!',
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
